@import './colors.scss';
.btn-save {
    background-color: $primary !important;
    color: $anti-flash-white !important;
    font-size: 14px !important;
    border: 1px solid $primary !important;
    border-radius: 3px !important;
    margin-right: 7px !important;
    .mat-button-wrapper {
        display: flex !important;
        align-items: center !important;
    }
    &:disabled {
        background-color: $gray-100 !important;
        cursor: no-drop !important;
    }
}

.btn-save-outline {
    background-color: $white !important;
    color: $primary !important;
    font-size: 14px !important;
    border: 1px solid $primary !important;
    border-radius: 3px !important;
    margin-right: 7px !important;
    .mat-button-wrapper {
        display: flex !important;
        align-items: center !important;
    }
    &:disabled {
        background-color: $gray-100 !important;
        cursor: no-drop !important;
    }
}

.btn-cancel {
    background-color: $cancel !important;
    border: 1px solid $cancel !important;
    border-radius: 3px !important;
    color: $text-color-default !important;
    .mat-button-wrapper {
        display: flex !important;
        align-items: center !important;
    }
}

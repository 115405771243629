.mat-form-field.form-dropdown-control {
    font-size: 14px;
    font-weight: 400;
    color: #878C8F;
    width: 100%;
    &.bg-transparent {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: transparent;
            }
        }
    }
    &.bg-dark {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #f2f2f2;
                border-color: #f2f2f2;
            }
        }
    }
    &.bg-white {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #fff;
                border-color: #fff;
            }
        }
    }
    &.form-dropdown-label {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                padding: 5px 5px 7px;
                .mat-form-field-infix {
                    padding: 9.5px 5px 7.5px;
                    mat-select {
                        .mat-select-trigger {
                            margin-top: 4px;
                        }
                    }
                    .mat-form-field-label-wrapper {
                        margin-top: 14px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-flex {
            padding: 4px 5px;
            background-color: #fff;
            border: 1px solid #D9DADB;
            border-radius: 4px;
            align-items: center;
            .mat-form-field-infix {
                padding: 10px 5px;
                border-top: 0;
                mat-select {
                    .mat-select-value {
                        color: #878C8F;
                    }
                }
                .mat-select-arrow-wrapper {
                    margin-top: 16px;
                    .mat-select-arrow {
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 6px solid;
                    }
                }
            }
            // .mat-form-field-suffix {
            //     .mat-datepicker-toggle {
            //         color: #05BE50;
            //     }
            // }
            // .mat-form-field-outline {
            //     .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
            //         border-width: 0px;
            //     }
            // }
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-wrapper {
            .mat-form-field-flex { 
                border: 1px solid red !important;
            }
        }
    }

}
$white: #fff !default;
$gray-100: #F5F5F5 !default;
$gray-150: #FBFBFB !default;
$gray-200: #f0f0f0 !default;
$gray-250: #D9DADB !default;
$gray-300: #bebebe !default;
$gray-400: #9b9b9b !default;
$gray-500: #4a4a4a !default;
$gray-600: #5F5F5F !default;
$black: #333333 !default;
 
$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '150': $gray-150,
    '250': $gray-250,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
  ),
  $grays
);

$text-color: #373F41 !default;
$text-color-default: #878C8F !default;
$dark-cerulean: #0B4582 !default;
$dark-liver: #878c8e !default;
$amber: #F3D03E !default;
$slick-blue: #6cccd6 !default;
$regal-blue: #163760 !default;
$planetary-down: #007096 !default;
$vivid-red: #FF3F15 !default;
$secondary: #D9D9D9 !default;
$dark-cyan: #07272D !default;
$green-haze: #3EA62D !default;
$light-green: #8EDF80 !default;
$sapphire: #0F69B4 !default;
$honolulu-blue: #0063af !default;
$old-silver:#828282 !default;
$orange: #ff9100 !default;
$shadow-grey: #C9C9C9 !default;
$philippine-grey: #8C8C8C !default;
$shadow-cyan: #004677 !default;
$white-aluminum: #A5A5A5 !default;
$anti-flash-white : #F2F2F2 !default;
$skyblue: #55aced !default;
$north-texas-green: #00A52E !default;
$lemon: #7ed321 !default;
$red: #EB0045 !default;
$background: #f4f5f7 !default;
$disabled: #bebebe !default;
$white-24-opacity: rgba(255, 255, 255, 0.24);
$green-blue-1-opacity:  rgba(15, 105, 180, 0.1);
$error: #EB3C46 !default;
$success: #00a94f !default;
$info: #55aced !default;
$warning: #E8A619 !default;
$light-skyblue: #e7f0f8 !default;
$hover-sidebar: #1169b4 !default;
$incomplete: #e2c534 !default;
$green: #01a42f !default;
$pale-red: #fbd8db !default;
$pale-green: #ccedd5 !default;
$alice-blue: #e6f0f8 !default;
$primary: #05BE50 !default;
$label-primary: #6688CA !default;
$label-title: #3361B8 !default;
$header-secondary: #ECEDED !default;
$label-percentage: #99B0DB !default;
$cancel: #ededed !default;
$progress: #E9F1FF !default;

$colors: () !default;
$colors: map-merge(
  (
    'text-color': $text-color,
    'text-color-default': $text-color-default,
    'black': $black,
    'white': $white,
    'dark-cerulean': $dark-cerulean,
    'dark-liver': $dark-liver,
    'amber': $amber,
    'vivid-red': $vivid-red,
    'slick-blue': $slick-blue,
    'regal-blue': $regal-blue,
    'honolulu-blue': $honolulu-blue,
    'shadow-grey': $shadow-grey,
    'philippine-grey': $philippine-grey,
    'shadow-cyan': $shadow-cyan,
    'old-silver': $old-silver,
    'white-aluminum': $white-aluminum,
    'anti-flash-white': $anti-flash-white,
    'planetary-down': $planetary-down,
    'green-haze': $green-haze,
    'light-green': $light-green,
    'sapphire': $sapphire,
    'orange': $orange,
    'lemon': $lemon,
    'green-blue-1-opacity': $green-blue-1-opacity,
    'north-texas-green': $north-texas-green,
    'red': $red,
    'background': $background,
    'skyblue': $skyblue,
    'disabled': $disabled,
    'success': $success,
    'warning': $warning,
    'error': $error,
    'info': $info,
    'incomplete': $incomplete,
    'gray-100': $gray-100,
    'gray-150': $gray-150,
    'gray-250': $gray-250,
    'pale-red': $pale-red,
    'pale-green': $pale-green,
    'green': $green,
    'alice-blue': $alice-blue,
    'primary': $primary,
    'secondary': $secondary,
    'label-primary': $label-primary,
    'label-title': $label-title,
    'header-secondary': $header-secondary,
    'label-percentage': $label-percentage,
    'cancel': $cancel,
    'progress': $progress
  ),
  $colors
);


@each $color-name, $color-value in $colors {
    .bg-#{$color-name} {
        background-color: $color-value !important;
    }
    .color-#{$color-name} {
      color: $color-value !important;
    }
    .border-color-#{$color-name} {
      border-color: $color-value !important;
    }
    .hover-color-#{$color-name}:hover {
      color: $color-value !important;
    }
}

.mail-hover {
    cursor: pointer;
    &:hover {
        color: $primary;
    }
}

.mat-form-field.form-date-control {
    font-size: 14px;
    font-weight: 400;
    color: #878C8F;
    width: 100%;
    &.bg-transparent {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: transparent;
            }
        }
    }
    &.bg-dark {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #f2f2f2;
                border-color: #f2f2f2;
            }
        }
    }
    &.bg-grey {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
              background-color: #f2f2f2 ;
              border: 1px solid #f2f2f2;
          }
        }
    }
    &.bg-dark-border {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #FBFBFB;
                border-color: #D9DADB;
            }
        }
    }
    &.bg-white {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #fff;
                border-color: #fff;
            }
        }
    }
    .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-flex {
            padding: 4px 5px;
            background-color: #fff;
            border: 1px solid #D9DADB;
            border-radius: 4px;
            align-items: center;
            .mat-form-field-infix {
                padding: 10px 5px;
                border-top: 0;
            }
        }
        .mat-form-field-suffix {
            .mat-datepicker-toggle {
                color: #05BE50;
            }
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                border: 1px solid red !important;
            }
        }
    }
}

@import './assets/scss/variables.scss';
@import './assets/scss/form-date-control.scss';
@import './assets/scss/form-dropdown-control.scss';
@import './assets/scss/form-input-control.scss';
@import './assets/scss/page-error.scss';
@import './assets/scss/fonts.scss';
@import './assets/scss/colors.scss';
@import './assets/scss/spacing.scss';
@import './assets/scss/utils.scss';
@import './assets/scss/stepper.scss';
@import './assets/scss/form-file-image.scss';
@import './assets/scss/form-speech-voice.scss';
@import './assets/scss/buttons.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background: $background !important;
}

@font-face {
  font-family: defaultM;
  src: url(assets/fonts/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
}

// @font-face {
//   font-family: defaultI;
//   src: url(assets/fonts/Inter-VariableFont_slnt\,wght.ttf) format('truetype');
// }

:host::ng-deep.defaultM {
  font-family: 'Montserrat', sans-serif !important;
}

// .defaultI{
//   font-family: 'Inter', sans-serif;
// }

.pointer {
  cursor: pointer;
}

.dashboard {
  background-color: white;
  /*padding: 30px;*/
}

.container {
  padding: 30px;
}

* {
  box-sizing: border-box;
}

// sidebar
app-sidebar {
  display: contents;

  .sidebar {
    display: contents;
    background: #0F191E;
  }
}
.border-bottom{
  border-bottom: 1px solid $color-lines !important;
}
.border-bottom-dotted{
  border-bottom: 1px dotted $color-lines !important;
}
.mat-expansion-panel {
  box-shadow: none !important;
}

.bottom {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.main-menu {
  background-color: $color-ligth-accent;
}

.nav-main {
  top: 0;
  bottom: 0;
  padding-left: 0;
  text-align: center;
}

//nav-items
.nav {
  padding-left: 0;
  text-align: center;

  ul {
    position: relative;
    list-style-type: none;
    li {
      padding: 0 0 2px;
    }
  }

  li {
    list-style-type: none;
    cursor: pointer;
  }

  a {
    text-decoration: none !important;
    text-transform: capitalize;
  }

  .subOption {
    a {
      color: black;
    }

    mat-icon {
      // position: absolute;
      // vertical-align: middle;
      // left: 125px;
      // top: 56px;
      // margin: 5px;
    }

    .sub-title {
      cursor: context-menu;

      p {
        font-size: 20px;
      }

      :hover {
        border-top-right-radius: 19px !important;
      }
    }

  }

  .subOption::after {
    content: "";
    // position: absolute;
    background-color: transparent;
    left: -21px;
    bottom: 0%;
    height: 65px;
    width: 21px;
    border-bottom-right-radius: 19px;
    box-shadow: 0 26px 0 0 #F2F2F2 // $color-gray

  }

  .hover:hover {
    background-color: #3361B8;
  }
  .subOption::before {
    content: "";
    // position: absolute;
    background-color: transparent;
    left: -21px;
    height: 44px;
    width: 21px;
    border-top-right-radius: 19px;
    box-shadow: 0 -14px 0 0 #F2F2F2;
  }

  .subOption2 {
    padding-left: 35px;

  }

  .dropdown:hover .subOption {
    display: initial;
  }
}

.subitem-menu{
  display: flex;
  text-align: left;
  margin-left: 31px;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px
}

.sidebar li .subOption {
  padding: 0;
  position: absolute;
  margin: 0;
  // @media screen and (max-width: 1288px) {
  //   left: 5.5rem !important;
  // }
  left: 92.5px !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  width: 250px;
  height: 100% !important;
  top: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1) !important;
  display: none;
  z-index: 1;
}
.tableOver{
  background-color: #FFFFFF;
  font-size: 14px !important;
  color: #4F4F4F !important;
 &:hover {
   background-color: #efecec !important;
 }
}

.isOver {
  display: initial !important;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
  margin-bottom: 10px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.nav-item-show {
  display: initial;
}

.active {
  /*background-color: #032776;*/
  div .b{
    border-left: 7px solid #00AA4D
  }
}

.router-active.active {
  background-color: #032776;

  p {
    color: #D9DADB;
  }

}

.btn-main {
  padding-left: 0;
  text-align: center;
}

.bottom-main {
  margin-top: 1rem;
}

.icon-person {
  border-radius: 5px;
  background-color: #ffffff69;
  text-align: center;
  margin: auto;
  padding: 4px 4px 0px 4px;
}

mat-divider {
  background-color: #fff;
}

.ibk-status-checked {
  background-color: $color-primary !important;
  color: #ffffff !important;
}
.ibkBSubEstado{
  width: 160px;
  height: 40px;
  border: 1px solid $color-primary !important;
  background-color: #E6F9EE !important;
  color: $color-primary;
}

//login

.right {
  width: 63%;
  height: inherit;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-top-left-radius: 74px;
  border-bottom-left-radius: 74px;
  background-color: white !important;
}

.logo-img {
  color: white;
  text-align: center;
}

.container-login {
  height: 100%;
  max-width: 100%;
}

.ibk-flex-row {
  -moz-flex-direction: row;
  flex-direction: row;
}

.image-logo {
  margin-left: 1rem;
  margin-top: 2rem;
  color: #fff;
  padding: 5rem;

}

.ibk-flex {
  background-color: #05BE50;
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
}

.left {
  background: url("assets/img/img-default.png");
  background-size: auto 100%;
  height: 100%;
  width: 37%;
  height: inherit;
  align-content: center;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-position: bottom;

  .VP_Logo {
    width: 329px;
    height: 113px;
    background: url("assets/img/logo_login1.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    align-content: center;
    align-items: flex-start;
    background-position: bottom;
  }

  p {
    font-size: 14px;
    line-height: 17.07px;
    // font-weight: 700;
  }
}

.container-one {
  width: 365px;
  display: flex;

  .one {
    width: 365px;
  }

  .one-error {
    display: inline-flex;
    border-radius: 4px;
    padding: 1rem 1rem 0px 1rem;
    background-color: #eb00451f;

    mat-icon {
      margin: 3px 14px 0 0;
    }

    p {
      color: black;
    }
  }

  .title {
    font-weight: 700;
    color: $color-accent;
    font-size: 36px;
    line-height: 43.88px;
  }

  mat-form-field {
    width: inherit;
    margin: 0;
  }

  button {
    width: 246px;
  }
}

.green {
  color: $color-primary;
}
.accent{
  color: $color-accent;
}

.no-color {
  background-color: transparent !important;
}

.transitionOF {
  transition: all 0.1s ease-in-out 1s, visbilty 0s linear 0.3s;
  display: none;
}

.transitionON {
  display: block !important;
  transition: all 0.1s ease-in-out 1s, visbilty 0s linear 0.3s;
}

.state {
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.rotate {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;

}

.show {
  display: none;
}

.noshow {
  display: block !important;
}

.border-button {
  border-radius: 5px !important;
  border: 1px solid #D9DADB !important;
}

@media screen and (max-width: 1280px) {
  .logo-img {
    img {
      width: 58px;
    }

    li {
      font-size: 12px;

      mat-icon {
        font-size: 20px;
      }

      .subOption {
        left: 7px !important;
      }
    }

  }

  .bottom-main {
    .icon-person {
      padding: 8px 9px 6px 12px;

      img {
        width: 32px;
      }
    }

    .logo-img {
      img {
        width: 32px;
      }

      p {
        font-size: 10px;
      }
    }
  }

}

.ibk-card {
  box-shadow: none !important;
  border-radius: 2px !important;
}


.mat-snack-bar-container {
  color: white;
  text-align: center;
}

.mat-simple-snackbar {
  justify-content: center;
  font-size: 1em;
}

.snackbar-error {
  background-color: #e74c3c;
}

.snackbar-warning {
  background-color: #f1c40f;
}

.snackbar-info {
  background-color: #239b56;
}

.loader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 1000;
  top: 0%;
  left: 0%;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.word-wrap {
  p {
    word-wrap: break-word;
  }

}
.disabled{
  background-color: transparent !important;
  border-radius: $color-lines !important;
  color: $color-lines !important;
  border: 1px solid $color-lines !important;
}
.disabled:hover{
  background-color: transparent;
}
.dotted{
  width: 170px !important;
  background-color: transparent !important;
  border-radius: $color-lines !important;
  color: $color-lines !important;
  border: 1px dotted $color-lines !important;

}
.dotted:hover{
  background-color: transparent;
}
.radio-group {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  min-width: 204px;
  /*width: 395px;*/
  height: 70px;
  flex-wrap: wrap;
  justify-content: center;

  mat-radio-group {
    flex-direction: column !important;
    display: flex !important;
    height: 80px;
    width: 280px;
    min-width: 118px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.checkbox-group {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  // width: 204px;
  // height: 70px;
  flex-wrap: wrap;
  justify-content: center;
}

// .mat-radio-outer-circle{
//   border-color: green !important ;
// }
.mat-radio-inner-circle {
  background-color: $color-primary !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $color-primary;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: $color-primary !important;
}

.example-list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  font-size: 12px !important;
  padding: 2px 10px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #05BE50 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  border: 1px solid #05BE50;
  background-color: white !important;
}
.mat-select .mat-select-arrow {
  color: #05BE50;
}

.no-drop {
  cursor: no-drop !important;
}

.line-dashed {
  border-top: 1px dashed #E0E0E0;
  margin: 0;
  // margin-bottom: 14px;
  border-bottom: 0;
}

.line-solid {
  border-top: 1px solid #E0E0E0;
  margin: 0;
  // margin-bottom: 14px;
  border-bottom: 0;
  border-left: 0;
}

.mat-button-disabled {
  cursor: no-drop !important;
}

a, button {
  outline: none;
}

a.input-disabled {
  cursor: no-drop !important;
}

.portfolio {
  mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 0 .75em 7px .75em;
        .mat-form-field-infix {
          padding: 6px 0 5px !important;
        }
      }
    }
  }

  .mat-form-field-type-mat-select .mat-form-field-label{
    font-size: 15px;
  }
}


.pt-15 {
  padding-top: 15px !important;
}

.tooltip-text {
  background-color: #B7BABC !important;
  border: 1px solid #B7BABC !important;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 35px !important;
  margin-left: -20px !important;
  overflow-wrap: anywhere;
}

.subItem-font{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6688CA;
}

.hover-border {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hover-border:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 2px solid #0033A1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-border:hover, .hover-border:focus, .hover-border:active {
  color: #0033A1;
}
.hover-border:hover:before, .hover-border:focus:before, .hover-border:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.blue-active{
  color: #0039A6 !important;
}

.blue-inactive{
  color: #99B0DB;
}

.menu-list{
  color: #6688CA;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  // color: red;
}
.menu-list:hover, .menu-list:focus, .menu-list:active {
  color: #0033A1;
}

.menu-inactive{
  color: #6688CA;
}

.font-salir{
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
}
// .multiline-tooltip {
//   white-space: pre-line;
//   background-color: white;
//   color: #373F41 !important;
//   width: 147px;
//   padding: 10px;
//   box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
//   border-radius: 8px;
// }
::ng-deep.mat-divider {
  border-top: 1.5px solid #6688CA;
}


.bottom-menu{
  color: white;
  text-align: center;
  padding: 10px 0
}

.back-sidebar{
  position: fixed;
  top: 0;
  right: 0;
  left: 8vw;
  bottom: 0;
  background: rgba(0,0,0,.5);
}

.mat-checkbox-disabled {
  cursor: no-drop !important;
}


//scroll
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:active {
  background-color: #BDBDBD;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

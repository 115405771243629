.disabled-control {
    opacity: .5;
    cursor: no-drop !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.position-relative {
    position: relative !important;
}

.position-sticky {
    position: sticky !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.rotate-180 {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-90 {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

$sizes: 45, 90, 135, 180, 270;
@each $size in $sizes {
  .rotate-#{$size} {
    -ms-transform: rotate(#{$size}deg);
    -moz-transform: rotate(#{$size}deg);
    -webkit-transform: rotate(#{$size}deg);
    transform: rotate(#{$size}deg);
  }
}

@for $i from 0 through 100 {
    .border-radius-#{$i} {
      border-radius: #{$i}px !important;
    }
}

@for $i from 0 through 10 {
    .border-solid-#{$i} {
        border: #{$i}px solid;
    }
    .border-bottom-solid-#{$i} {
        border-bottom: #{$i}px solid;
    }
    .border-top-solid-#{$i} {
        border-top: #{$i}px solid;
    }
    .border-right-solid-#{$i} {
        border-right: #{$i}px solid;
    }
    .border-left-solid-#{$i} {
        border-left: #{$i}px solid;
    }
}

@for $i from 0 through 50 {
    .gap-#{$i} {
      gap: #{$i}px !important;
    }
}

.display-none {
    display: none !important;
}

.display-block {
    display: block !important;
}

.display-flex {
    display: flex !important;
}

.text-align-center {
    text-align: center !important;
}
.text-align-left {
    text-align: left !important;
}
.text-align-right {
    text-align: right !important;
}

.align-content-center {
    align-content: center !important;
}
.align-content-start {
    align-content: flex-start !important;
}
.align-content-end {
    align-content: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end;
}

.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-around {
    justify-content: space-around;
}

.flex-direction-column {
    flex-direction: column !important;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.overflow-auto {
    overflow: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.white-space-normal {
    white-space: normal !important;
}

$indexSizes: 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500;
@each $size in $indexSizes {
    .z-index-#{$size} {
        z-index: $size;
    }
}
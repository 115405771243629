.form-speech-voice {
    display: flex;
    flex-direction: column;
    & > textarea {
        margin-top: 12px;
        height: 268px;
        outline: none;
        border-color: #B7BABC;
        color: #878C8F;
        border-radius: 4px;
        background-color: #F9F9F9;
        resize: none;
        padding: 10px 20px 0;
        font-size: 14px;

        &::placeholder {
            color: #B3B3B3;
        }

        &.ng-invalid.ng-touched {
            border-color: #EB0045;

            &+.voice {
                border-color: #EB0045;
            }
        }
    }

    & > .voice {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #F9F9F9;
        height: 47px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid #B7BABC;
        border-top: none;
        margin-top: -2px;

        &>.voice-message {
            font-size: 11px;
            color: #EB0045;
            padding-left: 33px;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: block;
            display: -webkit-box;
            max-width: 100%;
        }

        &>.voice-button {
            margin-right: 33px;
        }
    }
    & > small {
        color: #B7BABC;
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
        margin-top: 5px;
      }
}
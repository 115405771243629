.mat-form-field.form-input-control {
    font-size: 14px;
    font-weight: 400;
    color: #878C8F;
    width: 100%;
    &.bg-transparent {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: transparent;
            }
        }
    }
    &.bg-dark {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #f2f2f2;
                border-color: #f2f2f2;
            }
        }
    }
    &.bg-dark-border {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: #FBFBFB;
                border-color: #D9DADB;
            }
        }
    }
    .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-flex {
            padding: 4px 5px;
            background-color: #fff;
            border: 1px solid #D9DADB;
            border-radius: 4px;
            align-items: center;
            .mat-form-field-infix {
                padding: 10px 5px;
                border-top: 0;
                .mat-form-field-label-wrapper {
                    .mat-form-field-label {
                        padding-top: 3px;
                        padding-left: 3px;
                        .mat-placeholder-required {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                border: 1px solid red !important;
            }
        }
    }
    &.mat-form-field-should-float {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                .mat-form-field-infix {
                    .mat-form-field-label-wrapper {
                        .mat-form-field-label {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.form-file-image {
    &__name {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
            margin-left: 10px;
            width: 14px;
            height: 14px;
            margin-top: 10px;
            cursor: pointer;
        }
    }

    &__message {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        color: #B7BABC;
        margin-top: 8px;
        margin-left: 4px;
    }

    &__dropzone {
        width: 100%;
        height: 50%;
        padding: 30px;
        text-align: center;
        border: dashed 1px #BDBDBD;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 5px;
        min-height: 220px;
        input {
            opacity: 0;
            position: relative;
            z-index: 2;
            width: 5%;
            height: 10%;
            display: none;
        }

        p {
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.1px;
            font-size: 16px;
            width: 100%;
            margin: 2% 0;
            color: #878C8F;
            span {
                opacity: .3;
            }
        }
    }

    &__container-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        .error-form {
            color: #ea0045;
        }

        .error {
            font-size: 15px;
            line-height: 32px;
            text-align: center;
            letter-spacing: -0.1px;
            color: #EB0045;
            width: 100%;
            margin: 2% 0;
        }
    }
}
@for $i from 1 through 200 {
    .f-size-#{$i} {
      font-size: #{$i}px !important;
    }
}

$sizes: 100, 200, 300, 400, 500, 600, 700, 800, bold;
@each $size in $sizes {
  .f-weight-#{$size} {
    font-weight: $size;
  }
}

@for $i from 0 through 1000 {
  .width-#{$i} {
    width: #{$i}px !important;
  }
  .min-width-#{$i} {
    min-width: #{$i}px !important;;
  }
  .max-width-#{$i} {
    max-width: #{$i}px !important;;
  }
  .height-#{$i} {
    height: #{$i}px !important;
  }
  .min-height-#{$i} {
    min-height: #{$i}px !important;
  }
  .max-height-#{$i} {
    max-height: #{$i}px !important;
  }
  .width-#{$i}-per {
    width: #{$i}#{'%'} !important;
  }
  .line-height-#{$i} {
    line-height: #{$i}px !important;
  }
}

.width-auto {
  width: auto !important;
}

.width-fit {
  width: fit-content !important;
}
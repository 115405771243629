@for $i from 0 through 500 {
    .padding-#{$i} {
        padding: #{$i}px !important;
    }
    .padding-v-#{$i} {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;;
    }

    .padding-h-#{$i} {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;;
    }

    .padding-top-#{$i} {
        padding-top: #{$i}px !important;
    }

    .padding-bottom-#{$i} {
        padding-bottom: #{$i}px !important;
    }

    .padding-left-#{$i} {
        padding-left: #{$i}px !important;
    }

    .padding-right-#{$i} {
        padding-right: #{$i}px !important;
    }

    .margin-#{$i} {
        margin: #{$i}px !important;
    }

    .margin-top-#{$i} {
        margin-top: #{$i}px !important;
    }

    .margin-bottom-#{$i} {
        margin-bottom: #{$i}px !important;
    }

    .margin-left-#{$i} {
        margin-left: #{$i}px !important;
    }

    .margin-right-#{$i} {
        margin-right: #{$i}px !important;
    }

    .top-#{$i} {
        top: #{$i}px !important;
    }
    .right-#{$i} {
        right: #{$i}px !important;
    }
    .left-#{$i} {
        left: #{$i}px !important;
    }
    .bottom-#{$i} {
        bottom: #{$i}px !important;
    }
}

.page-error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    &__info {
        padding-right: 50px;
    }
}
.stepper-custom {
    position: relative;
    & > .mat-tab-group {
        & > .mat-tab-header {
            padding-top: 13px;
            background-color: #E9F1FF;
            border-radius: 10px;
            border-bottom: none;
            padding-left: 79px;
            padding-right: 79px;
            & > .mat-tab-label-container {
                & > .mat-tab-list {
                    & > .mat-tab-labels {
                        padding-bottom: 18px;
                        gap: 32px;
                        & > .mat-tab-label {
                            padding: 0;
                            opacity: 1;
                            min-width: 100px;
                        }
                    }
                    & > .mat-ink-bar {
                        height: 5px;
                    }
                }
            }
            & > .mat-tab-header-pagination {
                display: none;
            }
        }
    }
    &__paginator {
        position: absolute;
        top: 27px;
        // transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;
        &.paginator-before {
            left: 0;
            padding-left: 30px;
        }
        &.paginator-after {
            right: 0;
            transform: rotate(180deg);
            padding-left: 30px;
        }
    }
}